<template>
  <div style="width: 100%; margin-bottom: 20px">
    <div style="display: flex">
      <span v-if="$slots.icon" style="margin-right: 10px; margin-top: -4px">
        <slot name="icon" />
      </span>
      <h2 style="font-size: 1.2rem; font-weight: 700">
        {{ title }}
      </h2>
    </div>
    <div class="v-align" style="border-radius: 8px; border: 2px solid #eee; padding: 16px 20px" :style="{ height: height ? `${height}px` : 'auto' }">
      {{ text }}
    </div>
  </div>
</template>

<script>
// import IconCompass from './icons/IconCompass.vue'

export default {
  name: 'CardTitleIcon',
  components: {
    // IconCompass,
  },
  props: {
    height: {
      type: Number,
      default: null,
    },
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
}
</script>
