<template>
  <svg width="562" height="365" viewBox="0 0 562 365" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M308.995 124.005L86.2891 346.031C67.6075 364.656 37.6866 364.656 19.0049 346.031C0.331692 327.415 0.331696 297.612 19.0049 278.996L241.711 56.9685C260.393 38.3438 290.313 38.3438 308.995 56.9685C327.668 75.5848 327.668 105.388 308.995 124.005Z" stroke="url(#paint0_linear_880:3262)" stroke-width="10" />
    <path d="M542.995 86.0045L320.289 308.031C301.607 326.656 271.687 326.656 253.005 308.031C234.332 289.415 234.332 259.612 253.005 240.996L475.711 18.9685C494.393 0.34382 524.313 0.343824 542.995 18.9685C561.668 37.5848 561.668 67.3882 542.995 86.0045Z" stroke="url(#paint1_linear_880:3262)" stroke-width="10" />
    <defs>
      <linearGradient id="paint0_linear_880:3262" x1="264" y1="46.5" x2="145" y2="165.5" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F8AC18" />
        <stop offset="1" stop-color="#F8AC18" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="paint1_linear_880:3262" x1="490.5" y1="4.26958e-06" x2="319" y2="224.5" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F8AC18" />
        <stop offset="1" stop-color="#F8AC18" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'SvgRoundedTwo',
}
</script>
