<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#F8AC18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" stroke="#F8AC18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M4.92969 4.92993L9.16969 9.16993" stroke="#F8AC18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14.8301 14.8301L19.0701 19.0701" stroke="#F8AC18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14.8301 9.16993L19.0701 4.92993" stroke="#F8AC18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14.8301 9.16989L18.3601 5.63989" stroke="#F8AC18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M4.92969 19.0701L9.16969 14.8301" stroke="#F8AC18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'IconHelp',
  props: {
    size: {
      type: Number,
      default: 18,
    },
  },
}
</script>
