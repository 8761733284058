<style scoped>
.slider-item {
  background-size: cover;
  border-radius: 12px;
  text-align: left;
  font-size: 100%;
  display: block;
  margin-right: 2%;
}

.slider-fade-overlay {
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 10px;
  border-radius: 12px;
  padding: 25px;
  height: 380px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.slider-fade-overlay * {
  opacity: 0;
}

.slider-fade-overlay:hover * {
  opacity: 1;
}

.slider-fade-overlay > * {
  flex: 1;
}

.slider-fade-overlay:hover {
  background: linear-gradient(0deg, #111317 -15.25%, rgba(248, 172, 24, 0.2) 43.02%, rgba(17, 19, 23, 0) 108.41%);
}

.jjj {
}

.person-name {
  flex-direction: column;
  display: contents;
  font-size: 1.4rem;
}

.slider-container {
  white-space: normal;
}

.slider-meters {
  font-size: 1.3rem;
  line-height: 2rem;
  float: right;
}

.slider-title {
  font-size: 200%;
}

.slider-p p {
  line-height: 0.8rem;
}

div[class^='swiper-button'] {
  color: white;
}
</style>

<template>
  <div>
    <div class="fade-overlay">
      <swiper ref="slider" :options="options">
        <swiper-slide v-for="(item, index) in itemList" :key="index" :style="[style, { backgroundImage: `url(${item.imagem}` }]">
          <div class="slider-fade-overlay">
            <div>
              <b-button pill variant="warning" class="border btn-sm" @click="() => goto(item.url)">
                {{ item.cargo }}
              </b-button>
            </div>

            <div class="person-name">
              <p style="width: 30%">{{ item.nome }}</p>
            </div>
          </div>
        </swiper-slide>

        <div slot="button-prev" class="swiper-button-prev" />
        <div slot="button-next" class="swiper-button-next" />

        <div slot="loading">Carregando...</div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'SliderPhotos',

  components: {
    BButton,
    Swiper,
    SwiperSlide,
  },

  props: {
    items: {
      type: Number,
      default: 3,
    },
  },

  data() {
    return {
      itemList: [],
      style: { backgroundPosition: 'top', backgroundSize: 'cover', borderRadius: '12px' },
      options: {
        slidesPerView: 5,
        spaceBetween: 50,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1000: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          600: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    }
  },

  mounted() {
    this.obterItems()
  },

  methods: {
    async obterItems() {
      this.itemList = await this.$store.dispatch('site/obterPhotos')
    },

    goto(url) {
      window.location.href = url
    },

    goNext() {
      this.$refs.slider.$emit('slideNext')
    },

    goPrev() {
      this.$refs.slider.$emit('slidePre')
    },
  },
}
</script>
