<template>
  <b-row>
    <b-col sm="6">
      <card-title-icon :height="height" icon="compass" title="Propósito" text="Usar tecnologia e inovação para criar um mundo onde o investimento no mercado imobiliário seja acessível a todos.">
        <template #icon><icon-compass /></template>
      </card-title-icon>

      <card-title-icon :height="height" icon="compass" title="Visão" text="Ser reconhecida globalmente por levar oportunidades de investimentos imobiliários para o maior número de pessoas. ">
        <template #icon><icon-eye /></template>
      </card-title-icon>

      <card-title-icon :height="height" icon="compass" title="Valores Organizacionais" text="Adaptabilidade, transparência, perseverança, comprometimento e comunicação. ">
        <template #icon><icon-help /></template>
      </card-title-icon>
    </b-col>

    <b-col sm="6">
      <card-title-icon :height="height" icon="compass" title="Missão" text="Inovar e democratizar o mercado imobiliário.">
        <template #icon><icon-navigation /></template>
      </card-title-icon>

      <card-title-icon :height="height" icon="compass" title="Valores Morais" text="Integridade, confiança, lealdade, responsabilidade, honestidade, empatia, cooperação e respeito.">
        <template #icon><icon-smile /></template>
      </card-title-icon>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import CardTitleIcon from '@/views/site/components/CardTitleIcon.vue'

import IconCompass from '@/views/site/components/icons/IconCompass.vue'
import IconEye from '@/views/site/components/icons/IconEye.vue'
import IconHelp from '@/views/site/components/icons/IconHelp.vue'
import IconNavigation from '@/views/site/components/icons/IconNavigation.vue'
import IconSmile from '@/views/site/components/icons/IconSmile.vue'

export default {
  components: { BRow, BCol, CardTitleIcon, IconCompass, IconEye, IconHelp, IconSmile, IconNavigation },

  props: {
    height: {
      type: Number,
      default: null,
    },
  },
}
</script>
