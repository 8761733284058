<template>
  <div>
    <!-- banner -->
    <image-city text="Viemos para {democratizar} e aproximar o mercado imobiliário." style="margin-bottom: 60px" />

    <!-- main content -->
    <div class="site-container">
      <b-row>
        <b-col sm="6">
          <p>
            Uma empresa criativa e inovadora, voltada para pessoas com interesse em investir no mercado imobiliário. Queremos trazer maiores possibilidades para se investir em imóveis na planta como
            nunca antes feito, oferecendo investimentos para todos de um jeito simples, fácil e seguro.
          </p>
          <p>
            A Divvy Investimentos Imobiliários é uma plataforma que tem como principal objetivo criar oportunidades que trazem a segurança do mercado imobiliário e a transparência do mercado
            financeiro para nossos clientes.
          </p>
        </b-col>

        <b-col sm="6">
          <p>
            Trazemos inovação através da divisão de apartamentos na planta em cotas que são ofertadas dentro de nossa plataforma online para os clientes interessados em investir neste mercado de uma
            forma acessível nunca feita antes.
          </p>
          <p>
            Oferecemos aos nossos clientes uma alternativa de investimento no mercado imobiliário de forma 100% online que, além disso, também conta com todo auxílio e suporte de nossa equipe para
            tomada de decisão e acompanhamento durante todo o processo.
          </p>
        </b-col>
      </b-row>

      <about-cards :height="90" class="mt-2" />

      <svg-rounded-two style="z-index: -1; position: absolute; width: 440px; margin: -140px 0 0 0; right: 200px" />
    </div>

    <!--div style="width: 100%; height: 450px">
      <youtube color="#F8AC18" :rounded="false" />
    </div-->

    <div class="site-container">      
      <div style="width: 100%; height: 40vw; max-height: 700px">
        <youtube color="#F8AC18" src="https://www.youtube.com/embed/zJl1nUzkA38" />
      </div>
    </div>

    <div class="m-5" />

    <div class="site-container">
      <b-row>
        <b-col sm="8">
          <h1>Como surgiu a marca?</h1>
          <p>Focada na experiência do cliente, a Divvy traz todo o auxílio necessário para ajudar o investidor a fazer a sua melhor escolha.</p>
          <p>Tendo o símbolo de DIVISÃO como base, os pontos remetem aos principais pontos que a empresa pensou para facilitar a forma de investimento para um público totalmente novo.</p>
          <p>
            Com o objetivo de inovar e democratizar o mercado imobiliário por meio da divisão, a Divvy oferece a seus clientes um investimento seguro, com uma garantia real e ainda assim um ótimo
            retorno de seu capital.
          </p>
        </b-col>
        <b-col sm="4" :style="[brandStyle, { backgroundImage: `url('${require('../assets/images/g1003.png')}')` }]"> &nbsp; </b-col>
      </b-row>
    </div>

    <div class="site-container my-5">
      <div style="width: 100%; height: 40vw; max-height: 700px">
        <youtube color="#F8AC18" src="https://www.youtube.com/embed/rWCOfP8ltP4" />
      </div>
    </div> 

    <div class="text-white por-tras-da-divvy" :style="{ backgroundImage: `url('${require('../assets/images/bg-black-banner.png')}')` }">
      <div class="site-container py-5">
        <h1 class="text-white">Por trás da divvy</h1>
        <slider-photos />
      </div>
    </div>

    <div
      :style="{
        paddingTop: '60px',
        paddingBottom: '180px',
        backgroundImage: `url('${require('../assets/images/bg-particles.png')}')`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }">
      <div class="site-container text-center">
        <b-row>
          <b-col sm="2">&nbsp;</b-col>
          <b-col sm="8">
            <p>Uma empresa que surge em 2020 com um propósito claro e um único objetivo: Democratizar e Aproximar o mercado imobiliário. Prazer, somos a Divvy!</p>
            <p>
              A primeira empresa de investimentos no Brasil cujo propósito é democratizar o mercado imobiliário e aproximar as pessoas das grandes oportunidades que até então ficavam somente na mão de
              poucos.
            </p>
            <p>
              Somos únicos e especialistas no que fazemos, oferecemos oportunidades de investir em imóveis na planta por meio da compra de cotas. A oportunidade única de adquirir imóveis por meio de
              uma parceria com grandes construtoras do país, filtrando e trazendo no portfólio somente imóveis com alta segurança e potencial de valorização financeira.
            </p>
          </b-col>
          <b-col sm="2">&nbsp;</b-col>
        </b-row>
        <div style="width: 70px; height: 4px; background: #777; margin: 120px auto 40px auto">&nbsp;</div>

        <div>
          <h2>A Divvy não é</h2>
          <slider-text-vertical :interval="1500" :items="['Fundo imobiliário', 'Imobiliária tradicional', 'Construtora', 'Crowdfunding']" />
        </div>
      </div>
    </div>

    <div class="site-container">
      <newsletter-card />
    </div>

    <!-- footer -->
    <Footer />
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import AboutCards from '@/views/site/components/AboutCards.vue'
import SliderTextVertical from '@/views/site/components/SliderTextVertical.vue'
import Footer from '../components/parts/footer/Footer.vue'
import ImageCity from '../components/images/ImageCity.vue'
import SvgRoundedTwo from '../components/images/SvgRoundedTwo.vue'
import SliderPhotos from '../components/SliderPhotos.vue'
import NewsletterCard from '../components/NewsletterCard.vue'
import Youtube from '../components/Youtube.vue'

export default {
  name: 'SiteAbout',

  components: {
    SliderTextVertical,
    AboutCards,
    SliderPhotos,
    ImageCity,
    Footer,
    BRow,
    BCol,
    SvgRoundedTwo,
    NewsletterCard,
    Youtube,
  },

  data() {
    return {
      brandStyle: {
        minHeight: '180px',
        overflow: 'visible',
        backgroundSize: '60%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      },
    }
  },
}
</script>

<style scoped>
.site .app-content {
  padding: 0 !important;
}

@media screen and (min-width: 601) {
  h1 {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 600) {
  h1 {
    font-size: 1.6rem;
  }
}

h1 {
  margin-bottom: 40px;
  font-weight: 700;
  color: black;
}

h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: 700;
  color: black;
}

h3 {
  margin-bottom: 20px;
  font-size: 1.4rem;
  font-weight: 700;
  color: black;
}

h1,
h2,
h3,
p {
  line-height: 1.3;
}

.site-video {
  opacity: 0.4;
}

.site-video:hover {
  opacity: 1;
}

.scroll-hide p {
  scroll-snap-align: center;
  line-height: 1;
}
.scroll-hide {
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  margin-top: 60px;
}

.scroll-hide p {
  margin-bottom: 0;
}

.scroll-hide::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.por-tras-da-divvy {
  background-size: contain;
  background-repeat: repeat-x;
}
</style>
