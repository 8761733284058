<style scoped>
._marquee {
  font-size: 90px;
}

@media screen and (max-width: 800px) {
  ._marquee {
    font-size: 8vw;
  }
}
</style>

<template>
  <div style="height: 90px">
    <slider ref="slider" :options="options">
      <slideritem v-for="(item, index) in $props.items" :key="index">
        <p class="_marquee" style="font-weight: 700; color: #ffd178">{{ item }}</p>
      </slideritem>
    </slider>
  </div>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'

export default {
  name: 'SliderTextVertical',

  components: {
    slider,
    slideritem,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    interval: {
      type: Number,
      default: 2000,
    },
  },

  data() {
    return {
      options: {
        loop: true,
        Infinity: 3,
        currentPage: 0,
        loopedSlides: 1,
        slidesToScroll: 1,
        pagination: false,
        thresholdTime: 100,
        direction: 'vertical',
        autoplay: this.$props.interval,
      },
    }
  },
}
</script>
